import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import {
  GenericSectionSliceListContainer,
  GenericContainerList,
  ListItem,
  TextStyled,
} from './styles'
import IntersectionVisible from 'react-intersection-visible'

const GenericSectionSliceList = ({ query, lastSlice, simpleLayoutDesign }) => {
  const [isVisible, setIsVisible] = useState(false)
  const withLineDecoration = query.primary.list_line_decoration
  const observerProps = {
    onShow: () => setIsVisible(true),
    onHide: () => setIsVisible(false),
  }

  return (
    <IntersectionVisible {...observerProps}>
      <GenericSectionSliceListContainer
        lastSlice={lastSlice}
        simpleLayoutDesign={simpleLayoutDesign}
      >
        <GenericContainerList
          isVisible={isVisible}
          withLineDecoration={withLineDecoration}
          className="GenericContainerList"
        >
          {query.items.map((item, index) => {
            return (
              <Fade distance="15px" bottom key={index}>
                <ListItem>
                  <TextStyled type="tertiary-small" as="p">
                    {item.list.text}
                  </TextStyled>
                </ListItem>
              </Fade>
            )
          })}
        </GenericContainerList>
      </GenericSectionSliceListContainer>
    </IntersectionVisible>
  )
}

GenericSectionSliceList.propTypes = {
  query: PropTypes.object,
  lastSlice: PropTypes.bool,
  simpleLayoutDesign: PropTypes.bool,
}

export default GenericSectionSliceList
