import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'

export const StickyHeaderMainContainer = styled.div`
  position: relative;
  max-width: 2000px;
  margin: auto;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding-left: 20px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    position: ${props => (props.lastElement ? 'relative' : 'sticky')};
    top: 20%;
    z-index: 1;
  }
`

export const StickyHeaderContainer = styled.div`
  width: 100%;
  pointer-events: none;
  max-width: calc(100% - 20px);

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 600px;
    margin-left: 50px;
    position: ${props => (props.noPrimaryTitle ? 'absolute' : 'relative')};
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    margin-left: 100px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    margin-left: 186px;
  }
`

export const StickyHeaderContent = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-right: 200px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    margin-right: 20px;
  }
`

export const PrimaryTitle = styled(Title)`
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'bold')};
  margin-bottom: 15px;
  position: relative;
  top: -15px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 550px;
  }
`

export const SecondaryTitle = styled(Title)`
  position: relative;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'bold')};
  padding-top: 20px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 500px;
    padding-bottom: ${props => (props.simpleLayoutDesign ? '30px' : '50px')};
    padding-top: 50px;
  }
`
export const FixedTitleStyled = styled(Title)`
  position: relative;
  padding-top: 30px;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'bold')};

  span {
    letter-spacing: 0.5px;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      max-width: 500px;
    }
  }
`

export const MainBody = styled(Text)`
  font-weight: 200;
  padding-top: 20px;
  padding-bottom: 20px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 15px;
  }

  span {
    letter-spacing: 0.3px;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      max-width: 500px;
    }
  }
`
