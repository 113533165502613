import React from 'react'
import PropTypes from 'prop-types'
import GenericSectionSliceImage from './GenericSectionSliceZone/GenericSectionSliceImage'
import GenericSectionSliceText from './GenericSectionSliceZone/GenericSectionSliceText'
import GenericSectionSliceCta from './GenericSectionSliceZone/GenericSectionSliceCta'
import GenericSectionSliceList from './GenericSectionSliceZone/GenericSectionSliceList'
import GenericSectionSliceLogo from './GenericSectionSliceZone/GenericSectionSliceLogo'

import { GenericSectionSlice } from './styles'

const GenericSectionBody = ({
  query,
  componentPosition,
  stickyBody,
  noPrimaryTitle,
  simpleLayoutDesign,
  styleContext,
}) => {
  const slice = query.map((slice, index) => {
    switch (slice.__typename) {
      // These are the API IDs of the slices
      case 'PrismicBasicContainerBody1Image':
        return (
          <GenericSectionSliceImage
            key={slice.id}
            query={slice}
            lastSlice={index === query.length - 1}
          />
        )
      case 'PrismicBasicContainerBody1Text':
        return (
          <GenericSectionSliceText
            key={slice.id}
            query={slice}
            noPrimaryTitle={noPrimaryTitle}
            lastSlice={index === query.length - 1}
            simpleLayoutDesign={simpleLayoutDesign}
          />
        )
      case 'PrismicBasicContainerBody1Cta':
        return (
          <GenericSectionSliceCta
            key={slice.id}
            query={slice}
            lastSlice={index === query.length - 1}
            styleContext={styleContext}
          />
        )
      case 'PrismicBasicContainerBody1List':
        return (
          <GenericSectionSliceList
            key={slice.id}
            query={slice}
            stickyBody={stickyBody}
            lastSlice={index === query.length - 1}
            simpleLayoutDesign={simpleLayoutDesign}
          />
        )
      case 'PrismicBasicContainerBody1Logo':
        return (
          <GenericSectionSliceLogo
            key={slice.id}
            query={slice}
            lastSlice={index === query.length - 1}
          />
        )
      default:
        return null
    }
  })

  return (
    <GenericSectionSlice
      componentPosition={componentPosition}
      noPrimaryTitle={noPrimaryTitle}
      stickyBody={stickyBody}
    >
      {slice}
    </GenericSectionSlice>
  )
}

GenericSectionBody.propTypes = {
  query: PropTypes.array.isRequired,
  componentPosition: PropTypes.bool,
  stickyBody: PropTypes.bool,
  noPrimaryTitle: PropTypes.bool,
  simpleLayoutDesign: PropTypes.bool,
}

export default GenericSectionBody
