import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import { keyframes, css } from '@emotion/core'

const revealLineY = () => css`
  ${keyframes`
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  `} 1.5s ease-in-out forwards;
`

const revealLineY2 = () => css`
  ${keyframes`
    0% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0);
    }
  `} 1.5s ease-in-out forwards;
`

const revealLineX = () => css`
  ${keyframes`
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  `} 1.5s ease-in-out forwards;
`
const revealLineX2 = () => css`
  ${keyframes`
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  `} 1.5s ease-in-out forwards;
`

export const GenericSectionSliceTextContainer = styled.div`
  text-align: left;
  padding: 0 20px;
  padding-top: ${props => (props.simpleLayoutDesign ? '0px' : '50px')};
  padding-bottom: 50px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding-bottom: 20px;
    max-width: 674px;
    margin-bottom: ${props => (props.lastSlice ? '150px' : 'initial')};
    padding-top: ${props => (props.simpleLayoutDesign ? '140px' : '60px')};
  }
`
export const TextContainer = styled.div`
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: ${props => (props.withLineDecoration ? '150px' : 'initial')};
    margin-top: ${props => (props.withLineDecoration ? '250px' : 'initial')};
  }
`
export const TitleStyled = styled(Title)``

export const TextStyled = styled(Text)`
  font-weight: 500;
  white-space: pre-wrap;
  line-height: 1.45;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 570px;
  }

  &:before {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      content: '';
      position: absolute;
      width: 1px;
      height: calc(100% + 80px);
      top: -25px;
      left: -35px;
      z-index: 100000;
      transform-origin: bottom;
      transform: scaleY(0);
      animation: ${props =>
        props.isVisible && props.withLineDecoration
          ? revealLineY
          : !props.isVisible && props.withLineDecoration
          ? revealLineY2
          : 'initial'};
      animation-delay: 2s;
    }
  }

  &:after {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      content: '';
      position: absolute;
      width: calc(100% + 160px);
      top: -25px;
      left: -35px;
      height: 1px;
      transform-origin: left;
      transform: scaleX(0);
      animation: ${props =>
        props.isVisible && props.withLineDecoration
          ? revealLineX
          : !props.isVisible && props.withLineDecoration
          ? revealLineX2
          : 'initial'};
      animation-delay: 3.5s;
    }
  }
`
