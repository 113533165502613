import React from 'react'
import PropTypes from 'prop-types'
import { StyledLinkContainer } from './styles'
import LinkFormatter from 'components/LinkFormatter'

const StyledLink = ({ to, children, className, pageType }) => {
  return (
    <StyledLinkContainer>
      <LinkFormatter
        pageHandle={to}
        pageType={pageType}
        className={className ? `${className} StyledLink` : 'StyledLink'}
      >
        {children}
      </LinkFormatter>
    </StyledLinkContainer>
  )
}

StyledLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default StyledLink
