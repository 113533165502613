import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import AnimatedLine from 'components/AnimatedLine'
import {
  StickyHeaderContainer,
  StickyHeaderMainContainer,
  StickyHeaderContent,
  PrimaryTitle,
  SecondaryTitle,
  MainBody,
} from './styles'

const StickyHeader = props => {
  const { query, lastElement, type, simpleLayoutDesign, isFirstElement } = props

  const title_primary = query?.title_primary
  const font_weight_title_primary = query?.font_weight_title_primary
  const title_secondary = query?.title_secondary
  const font_weight_title_secondary = query?.font_weight_title_secondary
  const line_decoration = query?.line_decoration
  const component_position = query?.component_position
  const body = query?.body

  const primaryTitleRef = useRef(null)
  const secondaryTitleRef = useRef(null)
  const [primaryTitleHeight, setPrimaryTitleHeight] = useState(null)
  const [secondaryTitleHeight, setSecondaryTitleHeight] = useState(null)

  useEffect(() => {
    if (primaryTitleRef?.current) {
      setPrimaryTitleHeight(primaryTitleRef?.current?.clientHeight)
    }
  }, [primaryTitleRef?.current])

  useEffect(() => {
    if (secondaryTitleRef?.current) {
      setSecondaryTitleHeight(secondaryTitleRef?.current?.clientHeight)
    }
  }, [secondaryTitleRef?.current])

  return (
    <StickyHeaderMainContainer
      lastElement={lastElement}
      noPrimaryTitle={!title_primary?.text}
      componentPosition={component_position}
      simpleLayoutDesign={simpleLayoutDesign}
      isFirstElement={isFirstElement}
    >
      <StickyHeaderContainer
        noPrimaryTitle={!title_primary?.text}
        heightOffsetSecondary={secondaryTitleHeight}
      >
        <StickyHeaderContent>
          {title_primary?.text ? (
            <PrimaryTitle
              fontWeight={font_weight_title_primary}
              type="h2"
              as="h2"
              ref={primaryTitleRef}
            >
              {title_primary?.text}
            </PrimaryTitle>
          ) : (
            ''
          )}
          {title_secondary?.text ? (
            <SecondaryTitle
              fontWeight={font_weight_title_secondary}
              type="h3"
              as="h3"
              ref={secondaryTitleRef}
              noBody={!body.text}
              simpleLayoutDesign={simpleLayoutDesign}
            >
              {title_secondary?.text}
            </SecondaryTitle>
          ) : (
            ''
          )}{' '}
          {body?.text ? (
            <MainBody animated simpleLayoutDesign={simpleLayoutDesign}>
              {body?.text}
            </MainBody>
          ) : (
            ''
          )}{' '}
        </StickyHeaderContent>
      </StickyHeaderContainer>
      {line_decoration ? (
        <AnimatedLine
          className="StickyHeader__AnimatedLine"
          color={type === 'style 2' ? 'white' : '#043022'}
          shortLine
          heightOffset={primaryTitleHeight}
          heightOffsetSecondary={secondaryTitleHeight}
          noPrimaryTitle={!title_primary?.text}
        />
      ) : (
        <></>
      )}
    </StickyHeaderMainContainer>
  )
}

StickyHeader.propTypes = {
  query: PropTypes.object.isRequired,
  lastElement: PropTypes.bool,
  type: PropTypes.string,
  simpleLayoutDesign: PropTypes.bool,
  isFirstElement: PropTypes.bool,
}

export default StickyHeader
