import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import AnimatedImage from 'components/AnimatedImage'
import { GenericSectionSliceImageContainer } from './styles'

const GenericSectionSliceImage = ({ query, lastSlice }) => {
  const imageRef = useRef(null)

  return (
    <GenericSectionSliceImageContainer lastSlice={lastSlice}>
      {query?.items?.map((image, index) => {
        const isVertical = image?.image?.localFile?.childImageSharp?.fluid
          ?.aspectRatio
          ? image?.image?.localFile?.childImageSharp?.fluid?.aspectRatio < 1
          : false
        const withLineDecoration = image.image_line_decoration
        const addSpacingToRightMobile = image.mobile_add_spacing_right
        const imageOverflow = image.desktop_add_spacing_right
        const mobileSpaceAround = image.mobile_add_spacing_around
        const touchRightEdgeDesktop = image.desktop_touch_the_right_edge
        return image?.image?.localFile?.childImageSharp?.fluid ? (
          <AnimatedImage
            withLineDecoration={withLineDecoration}
            imageOverflow={imageOverflow}
            mobileSpaceAround={mobileSpaceAround}
            addSpacingToRightMobile={addSpacingToRightMobile}
            touchRightEdgeDesktop={touchRightEdgeDesktop}
            key={index}
          >
            <Img
              fluid={image?.image?.localFile?.childImageSharp?.fluid}
              alt={image?.image?.alt}
              className={`GenericSectionSlice__Image ${
                !isVertical ? 'horizontal' : 'vertical'
              } ${withLineDecoration ? 'withLineDecoration' : ''}`}
              ref={imageRef}
              key={index}
            />{' '}
          </AnimatedImage>
        ) : (
          <></>
        )
      })}
    </GenericSectionSliceImageContainer>
  )
}

GenericSectionSliceImage.propTypes = {
  query: PropTypes.object.isRequired,
  lastSlice: PropTypes.bool.isRequired,
}

export default GenericSectionSliceImage
