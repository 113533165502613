import styled from '@emotion/styled'
import Image from 'gatsby-image'

export const GenericSectionSliceLogoContainer = styled.div`
  margin-left: 20px;
  margin-top: 50px;
  margin-bottom: 50px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
    align-items: center;
    margin-bottom: 80px;
  }
`

export const LogoContainer = styled.div``

export const LogoStyled = styled(Image)`
  width: 183px;
  height: 69px;
  margin-right: 15px;
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 304px;
    height: 115px;
    margin-bottom: initial;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    margin-right: 50px;
  }
`
