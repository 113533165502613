import styled from '@emotion/styled'

export const StyledLinkContainer = styled.div`

.StyledLink {
  font-size: ${props => props.theme.fonts.m};
  color: ${props => props.theme.colors.primarygreen};
  font-weight: 500;
  cursor: pointer;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => props.theme.fonts.xl_large};
  }
}
`
