import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ImageContainer, ImageContent } from './styles'
import IntersectionVisible from 'react-intersection-visible'

const AnimatedImage = ({
  children,
  className,
  withLineDecoration,
  imageOverflow,
  mobileSpaceAround,
  addSpacingToRightMobile,
  touchRightEdgeDesktop,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const observerProps = {
    onShow: () => setIsVisible(true),
    onHide: () => setIsVisible(false),
  }

  const imageProps = {
    isVisible: isVisible,
    withLineDecoration: withLineDecoration ? withLineDecoration : null,
    mobileSpaceAround: mobileSpaceAround ? mobileSpaceAround : null,
    imageOverflow: imageOverflow ? imageOverflow : null,
    addSpacingToRightMobile: addSpacingToRightMobile
      ? addSpacingToRightMobile
      : null,
    touchRightEdgeDesktop: touchRightEdgeDesktop ? touchRightEdgeDesktop : null,
  }
  return (
    <IntersectionVisible {...observerProps} className={className}>
      <ImageContainer>
        <ImageContent {...imageProps}>{children}</ImageContent>
      </ImageContainer>
    </IntersectionVisible>
  )
}

AnimatedImage.propTypes = {
  children: PropTypes.array.isRequired,
  className: PropTypes.string,
  withLineDecoration: PropTypes.bool,
  imageOverflow: PropTypes.bool,
  mobileSpaceAround: PropTypes.bool,
  addSpacingToRightMobile: PropTypes.bool,
  touchRightEdgeDesktop: PropTypes.bool,
}

export default AnimatedImage
