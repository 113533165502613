import styled from '@emotion/styled'
import Text from 'components/Utilities/Text'
import { keyframes, css } from '@emotion/core'

const reveal1 = () => css`
  ${keyframes`
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  `} 1.5s ease forwards;
`

const reveal2 = () => css`
  ${keyframes`
    0% {
      width: 100%;
    }
    100% {
      width: 0%;
    }
  `} 1.5s ease forwards;
`

export const GenericSectionSliceListContainer = styled.div`
  padding-top: 30px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding-top: ${props => (props.simpleLayoutDesign ? '290px' : '90px')};
    margin-bottom: ${props => (props.lastSlice ? '150px' : 'initial')};
  }
`

export const GenericContainerList = styled.ul`
  list-style: none;
  max-width: 645px;
  position: relative;
  margin-left: 20px;

  &::before {
    content: '';
    position: absolute;
    width: 0;
    top: -40px;
    border-top: 1px solid;
    max-width: 645px;
    right: 0;
    animation: ${props =>
      props.isVisible && props.withLineDecoration
        ? reveal1
        : !props.isVisible && props.withLineDecoration
        ? reveal2
        : 'initial'};
    animation-delay: 1s;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-right: 40px;

    &::before {
      top: -25px;
    }
  }
`

export const ListItem = styled.li``

export const TextStyled = styled(Text)`
  font-weight: 100;
`
