import styled from '@emotion/styled'

export const GenericSectionSlice = styled.div`
display: block;
height: auto;
margin-top: 30px;

@media(min-width: ${props => props.theme.breakpoints.md}) {
  margin-left: 50%;
  margin-top: ${props => props.componentPosition || props.noPrimaryTitle && !props.stickyBody ? '35px' : props.stickyBody ? '-270px': '-500px'};
} 
`