import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IntersectionVisible from 'react-intersection-visible'
import Fade from 'react-reveal/Fade'
import {
  GenericSectionSliceTextContainer,
  TextContainer,
  TitleStyled,
  TextStyled,
} from './styles'

const GenericSectionSliceText = ({
  query,
  lastSlice,
  noPrimaryTitle,
  simpleLayoutDesign,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <IntersectionVisible
      onShow={() => setIsVisible(true)}
      onHide={() => setIsVisible(false)}
    >
      <GenericSectionSliceTextContainer
        lastSlice={lastSlice}
        noPrimaryTitle={noPrimaryTitle}
        simpleLayoutDesign={simpleLayoutDesign}
        className={`GenericSectionSliceTextContainer`}
      >
        {query.items.length > 0 &&
          query?.items?.map((element, index) => {
            const withLineDecoration = element.text_line_decoration

            return (
              <TextContainer
                key={index}
                withLineDecoration={withLineDecoration}
              >
                {element?.text_title?.text ? (
                  <Fade distance="30px" bottom>
                    <TitleStyled>{element?.text_title?.text}</TitleStyled>
                  </Fade>
                ) : (
                  <></>
                )}{' '}
                {element?.text_body?.html ? (
                  <Fade distance="30px" bottom>
                    <TextStyled
                      type="primary"
                      as="div"
                      className="GenericContainerList"
                      withLineDecoration={withLineDecoration}
                      isVisible={isVisible}
                      dangerouslySetInnerHTML={{
                        __html: element?.text_body?.html,
                      }}
                    />
                  </Fade>
                ) : (
                  <></>
                )}
              </TextContainer>
            )
          })}
      </GenericSectionSliceTextContainer>
    </IntersectionVisible>
  )
}

GenericSectionSliceText.propTypes = {
  query: PropTypes.object.isRequired,
}

export default GenericSectionSliceText
