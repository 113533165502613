import styled from '@emotion/styled'
import LinkFormatter from 'components/LinkFormatter'

export const GenericSectionSliceCtaContainer = styled.div`
  margin: 10px 0 80px;
  padding-left: 20px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 40px 0 60px;
  }
`

export const ButtonLinkFormatter = styled(LinkFormatter)`
  text-decoration: none;
  &.style-2 {    
    button {
      transition: border 1.5s;
      will-change: border;
      border: 3px solid ${props => props.theme.colors.lightblue};
    }
  }
`
