import styled from '@emotion/styled'
import { keyframes, css } from '@emotion/core'

const reveal1 = () => css`
  ${keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `} 1.5s ease forwards;
`

const revealLineY = () => css`
  ${keyframes`
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  `} .5s ease-in-out forwards;
`

const revealLineY2 = () => css`
  ${keyframes`
    0% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0);
    }
  `} .5s ease-in-out forwards;
`

const revealLineX = () => css`
  ${keyframes`
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  `} 1s ease-in-out forwards;
`
const revealLineX2 = () => css`
  ${keyframes`
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  `} 1s ease-in-out forwards;
`

export const ImageContainer = styled.div`
  min-height: 180px;
  margin-top: 50px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    min-height: 480px;
    margin-top: 150px;
  }
`

export const ImageContent = styled.div`
  opacity: 0;
  transform: opacity;
  transform-origin: bottom;
  animation: ${props => (props.isVisible ? reveal1 : 'initial')};
  position: relative;
  height: 100%;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding-right: ${props =>
      props.mobileSpaceAround
        ? '20px'
        : props.addSpacingToRightMobile
        ? '40px'
        : 'initial'};
    padding-left: ${props => (props.mobileSpaceAround ? '20px' : 'initial')};
  }

  .vertical {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin-left: ${props => (props.imageOverflow ? '-70px' : 'initial')};
    }
  }

  .GenericSectionSlice__Image {
    object-fit: contain;
    padding-bottom: 49px;
    position: relative;
    overflow: visible !important;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding-bottom: 70px;
      bottom: ${props => (props.lastSlice ? '-50px' : 'unset')};
      margin-left: ${props => (props.imageOverflow ? '-85px' : '80px')};
    }

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: calc(100% - 171px);
      bottom: -50px;
      left: 70px;
      z-index: 100000;
      transform-origin: top;
      transform: scaleY(0);
      animation: ${props =>
        props.isVisible && props.withLineDecoration
          ? revealLineY
          : !props.isVisible && props.withLineDecoration
          ? revealLineY2
          : 'initial'};
      animation-delay: 2s;

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        height: calc(100% - 171px);
        bottom: 85px;
        left: ${props => (props.imageOverflow ? '80px' : '-80px')};
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: calc(100% - 50px);
      bottom: -50px;
      left: 70px;
      height: 1px;
      transform-origin: left;
      transform: scaleX(0);
      animation: ${props =>
        props.isVisible && props.withLineDecoration
          ? revealLineX
          : !props.isVisible && props.withLineDecoration
          ? revealLineX2
          : 'initial'};
      animation-delay: 3s;

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        width: calc(100% + 264px);
        bottom: 85px;
        left: ${props => (props.imageOverflow ? '80px' : '-80px')};
      }
    }
  }

  .vertical {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      max-width: 570px;
    }
  }

  .horizontal {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      max-width: ${props =>
        props.imageOverflow
          ? '730px'
          : props.touchRightEdgeDesktop
          ? '840px'
          : '520px'};
      margin-left: ${props => (props.imageOverflow ? '-70px' : '0px')};
    }
  }
`
