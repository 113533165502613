import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import Button from 'components/Utilities/Button'
import StyledLink from 'components/Utilities/StyledLink'
import { GenericSectionSliceCtaContainer, ButtonLinkFormatter } from './styles'

const GenericSectionSliceCta = ({ query, lastSlice, styleContext }) => {
  return query?.items?.length > 0 ? (
    <GenericSectionSliceCtaContainer lastSlice={lastSlice}>
      {query?.items?.map((cta, index) => {
        if (cta?.cta_styling === 'link') {
          return cta?.cta_link?.uid && cta?.cta_label?.text ? (
            <Fade distance="30px" bottom key={index}>
              <StyledLink to={cta?.cta_link?.uid} pageType={cta?.page_type}>
                {cta?.cta_label?.text}
              </StyledLink>
            </Fade>
          ) : (
            <></>
          )
        } else if (cta?.cta_styling === 'button') {
          return cta?.cta_link?.uid && cta?.cta_label?.text ? (
            <Fade distance="30px" bottom key={index}>
              <ButtonLinkFormatter
                className={styleContext.styleActivated === "style 2" ? "style-2" : ""}
                pageHandle={cta?.cta_link?.uid}
                pageType={cta?.page_type}
              >
                <Button>{cta?.cta_label?.text}</Button>
              </ButtonLinkFormatter>
            </Fade>
          ) : (
            <></>
          )
        } else {
          return
        }
      })}
    </GenericSectionSliceCtaContainer>
  ) : (
    <></>
  )
}

GenericSectionSliceCta.propTypes = {
  query: PropTypes.object.isRequired,
  lastSlice: PropTypes.bool,
}

export default GenericSectionSliceCta
