import React from 'react'
import PropTypes from 'prop-types'
import {
  GenericSectionSliceLogoContainer,
  LogoContainer,
  LogoStyled,
} from './style'
import Fade from 'react-reveal/Fade'

const GenericSectionSliceLogo = ({ query, lastSlice }) => {
  return (
    <GenericSectionSliceLogoContainer lastSlice={lastSlice} >
      {query?.items.length > 0  && query?.items?.map((item, index) => {
        return (
             <Fade distance="30px" bottom key={index}>
          <LogoContainer key={index}>
            <LogoStyled
              fluid={item?.logo?.localFile?.childImageSharp?.fluid}
              imgStyle={{ objectFit: 'contain' }}
              alt={item?.logo?.alt}
            ></LogoStyled>
          </LogoContainer>
           </Fade>
        )
      })}
    </GenericSectionSliceLogoContainer>
  )
}

GenericSectionSliceLogo.propTypes = {
  query: PropTypes.object.isRequired
}

export default GenericSectionSliceLogo
